.icon_container {
  position: relative;
  top: -1px;
  margin-left: 5px;
  i {
    font-size: 6px;
  }
}

.dropdown_item {
  background-color: var(--white);
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid var(--light-grey);

  .small_text {
    font-size: 12px !important;
    font-style: italic;
    line-height: 15px;
  }
}

.nav_icon {
  font-size: unset !important;
  align-self: flex-end;

  &_new_entry_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 14px;
    right: 4px;
  }
}