/* ------------------------------------------------------------------------------------------------- 
    Variables (HTML5)
------------------------------------------------------------------------------------------------- */

:root {
  /* Colors */
  --white: #ffffff;
  --shadow-white: rgba(255, 255, 2550, 0.2);
  --dark-black: #2e3131;
  --dark-transparent-black: #2e313180;
  --black: #000000;
  --light-black: #162125;
  --black-rgba: rgba(0, 0, 0, 0.5);
  --shadow-black-01: rgba(0, 0, 0, 0.1);
  --shadow-black: rgba(0, 0, 0, 0.2);
  --succes-green: #2eae4a;
  --validation-red-text: #d61919;
  --validation-red-border: #dc3545;
  --validation-red-hover: #b61515;
  --validation-bg-red: rgba(220, 53, 69, 0.25);
  --validation-bg-platform: #f7d1d1;
  --warning-yellow-border: #efc319;
  --warning-bg-yellow: #fcf3d1;
  --save-btn-border: #f1c40f;
  --link-color: #406d8c;
  --link-color-hover: #284a61;
  --light-grey: #ececec;
  --lighter-grey: #f7f7f7;
  --mid-grey: #d5d5d6;
  --opacity-grey: rgba(0, 0, 0, 0.125);
  --dark-grey: #b3b7ba;
  --darker-grey: #808184;
  --darkest-grey: #878c90;

  --primary-brand-color: var(--light-black);
  --primary-color-gradient: linear-gradient(135deg, #ba1c1e 0%, #9f1314 100%);
  --primary-selected-masterdetail: #EEE3C3;
  --primary-border: #2e3131;
  --primary-gold: #FFCC00;
  --primary-gold-darker: #eebf01;

  /* App brand and colors */
  --brand-name-advies: "Advies";
  --brand-name-instellingen: "Instellingen";
  --brand-name-documentbeheer: "Documenten";
  --brand-name-aanvragen: "Aanvragen";
  --brand-name-contract: "Contract";
  --brand-name-berekeningen: "Berekenen";
  --brand-name-color: var(--white);

  --orientation-gradient-purple: -webkit-linear-gradient(120deg, rgba(80, 46, 141, 1) 0%, rgba(60, 107, 162, 1) 100%);
  --advies-gradient-red: -webkit-linear-gradient(120deg, rgba(188, 37, 39, 1) 0%, rgba(225, 34, 40, 1) 100%);

  --adviesbox-red-gradient: linear-gradient(135deg, #ba1c1e 0%, #9f1314 100%);
  --klantdossier-gradient-orange: linear-gradient(134.34deg, #e28a23 0%, #bc2550 100%);
  --producten-blue-gradient: linear-gradient(134.34deg, #2382e2 0%, #791dc2 100%);
  --bemiddeling-light-blue-gradient: linear-gradient(134.34deg, #2499d2 0%, #053f9d 100%);
  --instellingen-grey-gradient: linear-gradient(134.34deg, #8a7b7b 0%, #c6c6c6 100%);
  --beheer-gradient-green: linear-gradient(134.34deg, #45af49 0%, #07a14f 100%);

  --message-gradient: linear-gradient(134.34deg, #b3b7ba 0%, #878c90 100%);

  /* Shadows */
  --shadow-01: rgba(0, 0, 0, 0.1);
  --shadow-02: rgba(0, 0, 0, 0.2);
  --shadow-03: rgba(0, 0, 0, 0.3);
  --shadow-04: rgba(0, 0, 0, 0.4);
  --shadow-05: rgba(0, 0, 0, 0.5);
  --shadow-06: rgba(0, 0, 0, 0.6);
  --shadow-07: rgba(0, 0, 0, 0.7);
  --shadow-08: rgba(0, 0, 0, 0.8);
  --shadow-09: rgba(0, 0, 0, 0.9);
  --shadow-1: rgba(0, 0, 0, 1);
  --box-schadow-cards: rgba(213, 213, 214, 0.6);
  --form-outline-shadow: rgba(141, 115, 108, 0.25);

  /* Menu */
  --sidemenu-bg-color: var(--light-black);
  --sidemenu-open-color: var(--mid-grey);
  --sidemenu-link-active-color: rgba(37, 136, 203, 0.5);
  --sidemenu-border-bottom-minimize: rgba(142, 142, 143, 0.2);
  --sidemenu-text-color: var(--text-color);
  --sidemenu-loading-color: var(--teal);
  --sidemenu-error-color: #dc3545;
  --sidemenu-active-item-bg-color: rgba(79, 81, 81, 0.3);
  --sidemenu-hover-item-bg-color: rgba(79, 81, 81, 0.15);

  /* Text */
  --text-color: var(--dark-black);
  --header-color: var(--dark-black);
  --subtitle-color: var(--darkest-grey);

  /* Forms */
  --placeholder-text: var(--darkest-grey);
  --border-color-normal: var(--opacity-grey);
  --border-color-focus: var(--dark-transparent-black);
  --disabled-bg-color: var(--light-grey);
  --disabled-border-color: var(--mid-grey);
  --disabled-text-color: var(--darkest-grey);

  /* Dropdown */
  --dropdown-bottom-border: rgba(219, 219, 219, 1);

  /*Radiobuttons */
  --radio-border: var(--dark-grey);
  --radio-border-focus: var(--dark-black);
  --radio-border-unselected: var(--dark-grey);
  --radio-border-disabled: var(--mid-grey);
  --radio-textcolor-disabled: var(--darkest-grey);
  --radio-background: var(--dark-black);
  --radio-background-disabled: var(--light-grey);

  /* Checkboxes */
  --checkbox-border: var(--dark-grey);
  --checkbox-border-focus: var(--dark-black);
  --checkbox-border-disabled: var(--mid-grey);
  --checkbox-textcolor-disabled: var(--darkest-grey);
  --checkbox-background: var(--dark-black);
  --checkbox-background-disabled: var(--light-grey);

  /* Grid */
  --grid-border-color: var(--mid-grey);

  /* Sizes */
  --full-width: 100%;
  --extra-large: 175px;
  --large: 147px;
  --medium: 100px;
  --smedium: 75px;
  --small: 48px;

  /* Fonts */
  --font-h1: 40px;
  --font-h2: 32px;
  --font-h3: 26px;
  --font-h4: 22px;
  --font-brand: 20px;
  --font-p: 16px;
  --font-label: 15px;
  --font-input: 15px;
  --font-btn: 14px;
  --font-small: 13px;
  --font-th: 12px;
}

$primary-brand-hover-color: #737373;
$validation-red-hover-color: #d61919;

/* Path */
$images: "/images/";
$icons-path: "/images/icons/";

/* Custom Bootstrap sizes (w-50) */
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);
