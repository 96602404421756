.top-navigation {  
    &__icon {
      margin-right: .5rem;
    }
  
    &__item {    
      & > .btn-link {
        height: 2.25rem;
        font-weight: 700;
        text-transform: none !important;
      }
    }
}

.dropdown-item {
  text-transform: none !important;
  letter-spacing: normal !important;
}