.pdf {
  &_padding {
    margin-top: 30px;
  }

  &_header {
    color: #143f79;
    font-size: 30px;
    font-weight: bold;

    &_container {
      padding-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }

  &_page {
    max-width: 30.3cm;
    min-width: 30.3cm;
    min-height: 40.6cm;
    height: 40.6cm;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &_hide {
    position: absolute;
    left: -10000px;
    top: 0px;
  }

  &_footer {
    display: flex;
    max-width: 850px;
    padding-bottom: 100px;
    flex-direction: column;

    &_header {
      padding-bottom: 30px;
    }

    &_sub {
      display: flex;
      flex-direction: column;

      &_rights {
        font-style: italic;
      }
    }
  }

  &_table {
    min-width: 850px;
    max-width: 850px;
    margin-top: 50px;
    margin-bottom: 50px;

    &_right_align {
      text-align: end;
      padding-right: 5px;
    }

    &_uitgangspunten {
      &_header {
        &_left {
          font-weight: bold;
          color: white;
          text-align: right;
          padding-right: 10px;
          min-width: 350px;
          max-width: 350px;
          background-color: #143f79;
          border-right: 1px solid #2e5588;
        }
        &_right {
          background-color: #143f79;
          min-width: 500px;
          max-width: 500px;
        }
      }
    }
    &_header {
      max-width: 300px;
      min-width: 300px;
      color: white;
      background-color: #143f79;
      padding-left: 10px;

      &_center {
        border-left: 1px solid #2e5588;
        border-right: 1px solid #2e5588;
        max-width: 250px;
        min-width: 250px;
        padding-left: 10px;
        color: white;
        background-color: #143f79;
      }
    }
    &_dataTable {
      outline: 1px solid #768fb0;

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }

    &_data {
      padding-left: 10px;

      &_info {
        border-right: 1px solid #446693;
        text-align: right;
        padding-right: 10px;
      }

      &_header {
        color: white;
        padding-left: 5px;
        border-right: 1px solid #2e5588;
        background-color: #143f79;

        &_first {
          max-width: 250px;
          min-width: 250px;
        }

        &_second {
          max-width: 75px;
          min-width: 75px;
          text-align: right;
          padding-right: 5px;
        }

        &_third {
          max-width: 75px;
          min-width: 75px;
          text-align: right;
          padding-right: 5px;
        }

        &_fourth {
          max-width: 112.5px;
          min-width: 112.5px;
          text-align: right;
          padding-right: 5px;
        }

        &_fifth {
          max-width: 112.5px;
          min-width: 112.5px;
          text-align: right;
          padding-right: 5px;
        }
      }
    }
  }
}
