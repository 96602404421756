/*------------------------------------------------------------------------------------------------- 
    
    Base file, set-up the Sass environment

------------------------------------------------------------------------------------------------- */

/*------------------------------------------------------------------------------------------------- 
    Import all Module files
------------------------------------------------------------------------------------------------- */

@import "../modules/_all.scss";

/*------------------------------------------------------------------------------------------------- 
    Generic
------------------------------------------------------------------------------------------------- */
body {
  background: #CAD5E3;
  height: 100vh;
  -ms-overflow-style: scrollbar;
  @include font-title(var(--text-font), 400, 13px, var(--text-color));

  @include breakpoint($medium-screens) {
    padding-top: 0;
  }
}

.wrapper {
  @include breakpoint($no-more-resize) {
    margin: 0 auto;
    width: 100%;
    min-width: 992px;
    height: auto;
  }

  .navbar {
    @include breakpoint($no-more-resize) {
      margin: 0 auto;
      width: 100%;
    }
  }
  .sidebar-nav-container {
    @include breakpoint($no-more-resize) {
      position: relative;
      padding-bottom: 100%;
    }
  }
}

h1 {
  @include font-title(var(--header-font), 400, 25px, var(--header-color));
}

h2,
h3,
h4 {
  @include font-title(var(--header-font), 700, 14px, var(--header-color));
  display: inline-block;
}

p,
a,
label,
.small-text {
  color: inherit;
}

label {
  display: inline-flex;
}

.small-text {
  @include font-title(var(--text-font), 400, 13px);
}

.subtitle {
  @include font-title(var(--text-font), 700, 13px, var(--text-color));
  line-height: 17px;
  display: inline-block;
  padding: 12px 0 3px 0.3rem;
}

sup {
  color: var(--darkest-grey);
}

.sup {
  font-size: 11px;
}

.small {
  font-size: var(--font-small);
}

.small-input-padding {
  padding-left: 5px;
  padding-right: 5px;

  /* span {
    padding-right: 5px;
  } */
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-group {
  width: auto;
  height: 100%;

  .input-group {
    width: 100%;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.mx-55 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.mt-01 {
  margin-top: 0.1rem;
}

.mx-1-35 {
  margin-left: 0.35rem;
  margin-right: 0.35rem;
}

.mr-23 {
  margin-right: 2.23rem;
}

.no-margin {
  margin: 0 !important;

  .btn-outline-secondary {
    margin-left: 0;
  }
}

.pl-lg-0 {
  @include breakpoint($large-screens-card-padding) {
    padding-left: 1rem !important;
  }
}

.input-rood {
  color: var(--validation-red-text);
}

.mt-checkbox {
  margin-top: 5px !important;
}

.border-bottom {
  margin: 1px 0;
  margin: 1px 5px;
  border-bottom: 1px solid var(--dark-black) !important;
}

.link {
  color: var(--link-color);
  margin: 10px 0;

  &:hover {
    text-decoration: none;
  }

  span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .externallink {
    font-size: 8px;
    position: absolute;
    top: 5px;
    margin-left: 3px;
  }

  &:hover {
    color: var(--link-color-hover);
  }
}

input,
select,
textarea {
  &.full-width {
    width: var(--full-width) !important;
  }

  &.extra-large {
    width: var(--extra-large) !important;
  }

  &.large {
    width: var(--large) !important;
  }

  &.medium {
    width: var(--medium) !important;
  }

  &.smedium {
    width: var(--smedium) !important;
  }

  &.small {
    width: var(--small) !important;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.grid-cell {
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
    padding: 0 !important;
  }
}

.full-size {
  width: var(--full-width);
}

.form-group {
  margin-bottom: 3px;
  border-bottom: 1px solid var(--border-color-normal);
  font-size: var(--font-label);
}

.form-row {
  margin-left: 0;
  margin-right: 0;
}

.col-form-label {
  display: inline-flex;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 0;
  line-height: 1.5;
}

.text-container {
  padding: 13px 0.3rem 0;

  h2 {
    display: inline-block;
    padding: 0 0.625rem 0 0;
  }

  .button-container {
    display: inline-block;
    padding: 1rem 0;
  }
}

.flex-row {
  .all-cards-same-size {
    flex: 1 1 500px;

    @include breakpoint($medium-screens) {
      flex: 0;
    }
    @include breakpoint($xxl-screens) {
      flex: 0;
    }
  }
}

.flex-column {
  @include breakpoint($medium-screens) {
    flex-direction: row !important;
  }

  .card-adviesbox {
    min-width: 500px;
    height: auto;
  }

  .all-cards-same-size {
    flex: 0;

    @include breakpoint($medium-screens) {
      flex: 0;
    }
  }

  .p-1 {
    padding: 0 0.25rem !important ;
  }

  &.pl-xl-0 {
    @include breakpoint($extra-large-screens) {
      padding-left: 1rem !important;
    }

    @include breakpoint($extra-small-screens) {
      padding-left: 1rem !important;
    }
  }
}

.hidden-flexbox {
  flex-grow: 1;

  @include breakpoint($xxl-screens) {
    display: none;
  }

  .card-adviesbox {
    height: 0 !important;
    background-color: transparent;
    margin: 0;
    padding: 0.1rem;
    @include box-shadow(none);

    &:hover,
    &:focus {
      @include box-shadow(none);
    }
  }
}

.medeaanvrager-container {
  @include breakpoint($xxl-screens) {
    width: 100%;
  }
  .card-adviesbox {
    width: 500px;

    @include breakpoint($xxl-screens) {
      height: auto;
    }
  }
}

/* App colors */

.orientatie-color {
  background: var(--orientation-gradient-purple);
}

.beheer-color {
  background: var(--beheer-gradient-green);
}

.instellingen-color {
  background: var(--instellingen-gradient-blue);
}

.advies-color {
  background: var(--advies-gradient-red);
}

.klantdossier-color {
  background: var(--klantdossier-gradient-orange);
}

.consument-color {
  background: var(--consument-gradient-blue);
}

.table {
  margin-bottom: 0;

  &-header {
    color: var(--darkest-grey);
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    vertical-align: bottom;
    padding: 8px 10px 5px;
  }

  &-data {
    line-height: 17px;
    padding: 8px 10px;
    word-break: break-all;
  }

  &-data,
  &-header {
    display: inline-flex;
    width: 93px;
    padding: 8px 10px;
  }

  &-data,
  &-header {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(9) {
      justify-content: start;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      justify-content: flex-end;
    }
  }

  &-striped {
    &:nth-child(even) {
      background: var(--lighter-grey);
      border: 1px solid var(--mid-grey);
      border-top: none;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid var(--mid-grey);
      }
    }
    &:nth-child(odd) {
      background: var(--white);
      border: 1px solid var(--mid-grey);
    }
  }
}

.producten {
  &-table {
    padding: 8px !important;
    border: 1px solid var(--mid-grey) !important;
    border-radius: 0;

    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:nth-child(even) {
      background: var(--lighter-grey);
    }
    &:nth-child(odd) {
      background: var(--white);
    }

    .CheckboxInput_checkbox_label__GMaB6 {
      margin-top: -4px;
    }
  }
}

.totaal-container {
  display: inline-flex;
  padding-right: 3px;
  padding-left: 4px;
}

@each $size in small, smedium, medium, large, extra-large, full-width {
  .#{$size} {
    @if $size == small {
      .totaal-container {
        width: var(--small);
        justify-content: flex-end;
      }
    }
    @if $size == smedium {
      .totaal-container {
        width: var(--smedium);
        justify-content: flex-end;
      }
    }
    @if $size == medium {
      .totaal-container {
        width: var(--medium);
        justify-content: flex-end;
      }
    }
    @if $size == large {
      .totaal-container {
        width: var(--large);
        justify-content: flex-end;
      }
    }
    @if $size == extra-large {
      .totaal-container {
        width: var(--extra-large);
        justify-content: flex-end;
      }
    }
    @if $size == full-width {
      min-width: 85%;
      .totaal-container {
        width: var(--full-width);
        justify-content: flex-end;
      }
    }
  }
}

.label-text-container {
  justify-content: flex-start;
}

.pr-48 {
  padding-right: ($spacer * 1.81) !important;
}

.border-container {
  @include flex();
  align-items: center;

  .calculated-border {
    width: 90%;
    border-bottom: 1px solid var(--dark-black);
    margin: 0 5px;
  }

  .calculated-icon {
    @include font-title(var(--header-font), 700, 12px, var(--text-color));
    line-height: 3px;
    margin-left: 5px;
  }
}

.currency-with-icon {
  .totaal-container {
    width: 186px;
  }

  .btn-outline-secondary {
    margin-left: 11px;
  }
}

.invisible {
  visibility: hidden;
}

.message {
  width: 220px;
  font-size: 11px;
  font-weight: 700;
  color: var(--darkest-grey);
}

.input-field-right {
  .input-group {
    width: 150px;
    justify-content: flex-end;
  }
}

.scenario-order {
  .order {
    &:nth-child(2) {
      @include breakpoint($medium-screens) {
        order: 2;
      }
    }
  }
}

/* Werkt aan de hand van bootstrap $spacer */
.p-left {
  padding-left: $spacer * 0;

  @include breakpoint($medium-screens) {
    padding-left: $spacer;
  }

  @include breakpoint($large-screens-card-padding) {
    padding-left: $spacer;
  }
}

.mt-02 {
  margin-top: $spacer - 0.8;
}

.px-modal {
  padding-left: $spacer + 0.6 !important;
  padding-right: $spacer + 0.6 !important;
}

.p-x {
  padding-left: $spacer + 0.05;
  padding-right: $spacer + 0.05;
}

.px-03 {
  padding-left: $spacer - 0.7;
  padding-right: $spacer - 0.7;
}

.p-left-05 {
  padding-left: $spacer + 0.05;
}

.tooltip_container {
  position: relative;
  .question-tip {
    position: absolute;
    top: 8px;
  }
}

.max-width-165 {
  max-width: 165px;
}

.save-btn-position {
  position: fixed;
  top: -12px;
  margin-left: -10px;
  z-index: 1030;
}

.text-ellipsis {
  width: 60%;

  .totaal-container {
    @include ellipsis();
    width: 100%;
    display: inline-block;
  }
}

.scenario-read-only {
  .scenario-container {
    &:first-child {
      background: var(--white) !important;
      color: var(--darkest-grey);
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
    }
    &:nth-child(odd) {
      background: var(--lighter-grey);
    }
    &:nth-child(even) {
      background: var(--white);
    }
  }
}

.verpanding-warning {
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 3px 5px;
}

.font {
  &-color {
    &-dark-black {
      color: var(--dark-black);
    }
  }

  &-size {
    &-sm {
      font-size: 7px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.input-group {
  &-append {
    .btn {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
  }
}

.scrollbar {
  overflow: auto;
  &::-webkit-scrollbar-track {
    background-color: #bfbfc1;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: rgba($color: #000000, $alpha: 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #666666;
  }
}

.form-row > .col, .form-row > [class*="col-"] {
  padding: 0!important;
}